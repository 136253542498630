<template>
	<div class="wrap">
		<div class="bg"></div>
		<div class="head">
			<div class="login">
				<img class="img-logo" src="../../assets/img/logo_1.png" alt="logo">
			</div>
			<div class="title">{{$t('common.app')}}</div>
		</div>
		<div class="main">
			<div class="logo">
				<img class="img-logo" src="../../assets/img/logo.png" alt="logo">
			</div>
			<form class="form">
				<div class="row">
					<div class="form-required">*</div>
					<img class="icon" src="../../assets/img/yonghuming.png" alt="icon-username">
					<input type="text" v-model="params.mobile" :placeholder="$t('account.mobile')">
				</div>
				
				<div class="row">
					<div class="form-required">*</div>
					<img class="icon" src="../../assets/img/email.png" alt="icon-code">
					<input class="input_code" v-model="params.code" type="text" :placeholder="$t('account.note')">
					<div class="getCode" @click="getCode" v-if="time == 60">{{$t('account.send')}}</div>
					<div class="getCode" v-else>{{time}} S</div>
				</div>
				<div class="row">
					<div class="form-required">*</div>
					<img class="icon" style="width: 22px;height: 17px;" src="../../assets/img/card.png" alt="icon-card">
					<input type="text" v-model="params.nickname" :placeholder="$t('account.name')">
				</div>
				<div class="row">
					<div class="form-required">*</div>
					<img class="icon" src="../../assets/img/ziyuanxhdpi.png" alt="icon-pwd">
					<input type="password" v-model="params.password" :placeholder="$t('account.message4')">
				</div>
				<div class="row">
					<div class="form-required">*</div>
					<img class="icon" src="../../assets/img/ziyuanxhdpi.png" alt="icon-pwd">
					<input type="password" v-model="params.password_again" :placeholder="$t('account.confirm')">
				</div>
				<div class="row">
					<div class="form-required">*</div>
					<img class="icon" style="width: 22px;height: 17px;" src="../../assets/img/card.png" alt="icon-card">
					<input type="text" v-model="params.hospital" :placeholder="$t('account.hospital')">
				</div>
				<div class="row">
						<img class="icon" style="width: 22px;height: 17px;" src="../../assets/img/card.png" alt="icon-card">
						<input type="text" v-model="params.sale" :placeholder="$t('account.salesman')">
				</div>
				<div class="">
						<el-upload
						  class="upload-demo"
						  :action="$baseUrl+'/dabo/upload'"
						  :on-preview="handlePreview"
						  :on-success="handleChange"
						  :on-remove="handleRemove"
						  :file-list="fileList"
						  :before-upload="beforeUpload"
						  list-type="picture">
						  <div>
						  <span>{{$t('account.upload')}}</span><br/>
						  <span class="msg">({{$t('account.file')}})</span>
						  </div>
						</el-upload>
				</div>
				
				<input type="button" @click="reg" class="btn-login" :value="$t('account.register')">
				<div class="link-reg">
					<router-link to="/login">{{$t('account.btnLogin')}}</router-link>
				</div>
			</form>
		</div>
		<div class="foot">
			<!-- <span>闽ICP备 12021955号-4大博计算机辅助外固定支架系统</span> -->
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				time:60,
				params:{
					mobile:"",
					password:"",
					password_again:"",
					nickname:"",
					hospital:"",
					sale:"",
					image:[],
					code:""
				},
				fileList: []
      
			}
		},
		
		methods:{
			//上传文件类型判断
			beforeUpload(file){
				var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
				const extension = testmsg === 'jpg';
				const extension2 = testmsg === 'png';
				const extension3 = testmsg === 'bmp';
				const extension4 = testmsg === 'jpeg';
				if(!extension && !extension2 && !extension3 && !extension4) {
					this.$message({
						message: '上传文件只能是 jpg、png、bmp格式!',
						type: 'warning'
					});
				}
				return extension || extension2 || extension3 || extension4

			},
			handleChange(response, file, fileList){
				
				
				this.fileList = fileList.slice(-3);
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
		 	},
		  	handlePreview(file) {
				console.log(file);
		  	},
			getCode(){
				if(this.params.mobile){
					this.$api.sendSms({mobile:this.params.mobile}).then(res=>{
						this.$message.success(res.msg);
						if(res.code == 1){
							var timer = setInterval(rso=>{
								this.time--;
								if(this.time == 0){
									clearInterval(timer);
									this.time = 60;
								}
							},1000)
						}
					})
					.catch(err=>{
						this.$message.error(this.$t('account.message5'));
						
					})
				}else{
					this.$message.error(this.$t('account.mobile'))
				}
			},
			reg(){
				if(this.fileList.length > 0){
					this.fileList.map(res=>{
						this.params.image.push(res.response.data.url);
					})
				}
				this.params.image = this.params.image.join(",");
				this.$api.userApply(this.params).then(res=>{
					if(res.code == 1){
						this.params.mobile = this.params.password = this.params.password_again = this.params.hospital = this.params.sale = this.params.nickname = this.params.code = "";
						this.fileList = [];
						this.$message.success(res.msg);
						this.$router.push('/login');
					}else{
						this.$message.error(res.msg);
					}
					this.params.image = [];
				})
				.catch(err=>{
					this.params.image = [];
					// this.$message.error(this.$t('common.errMsg'));
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.upload-demo{background-color: #7ECDF6;text-align: center;color: #fff;margin-bottom: 21px;}
	.upload-demo div{background-color: #7ECDF6;height: 54px;display: flex;justify-content: center;align-items: center;color: #fff;}
	.input_code{width: 60% !important;}
	.getCode{text-align:center;width: 30%;display: inline-block;font-size: 20px;color: #7ECDF6;vertical-align: middle;cursor: pointer;}
	input:focus {
		outline:none;
		border: none;
	}
	.wrap{
		/* position: relative; */
	}
	.bg{
		background-image: url(../../assets/img/bg1.png);
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		background-color: #fff;
		background-position: center 0;
		position:fixed;
		top: 0;
		left: 0;    
		width:100%;
		height:100%;
		min-width: 1000px;
		z-index:-10;
		zoom: 1;
	}
	.head{
		position: absolute;
		left: 6.5%;
		top: 5%;
		display: flex;
		align-items: center;
	}
	.head .img-logo{
		width: 202px;
		margin-right: 53px;
	}
	.head .title{
		font-size: 36px;
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		color: #FFFFFF;
	}
	.main{
		width: 424px;
		/* height: 680px; */
		background: #FFFFFF;
		border-radius: 11px;
		padding: 24px 77px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-45%);
		margin-bottom: 15px;
	}
	.main .logo{
		text-align: center;
	}
	.main .img-logo{
		width: 135px;
		margin-top: 10px;
		margin-bottom: 40px;
	}
	.main .form .row{
		margin-bottom: 25px;
		padding-left: 83px;
		border: 1px solid #EAE8EB;
		border-radius: 8px;
		position: relative;
	}
	.main .form .flex{
		margin-bottom: 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 15px;
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		color: #D4D3D9;
	}
		
	.main .form .code{
		width: 209px;
		margin-bottom: 0;
	}

	.main .form .icon{
		position: absolute;
		left: 32px;
		top: 50%;
		width: 24px;
		height: 24px;
		transform: translate(0%,-50%);
	}
	.main .form .code .icon{
		width: 23px;
		height: 19px;
	}
	.main .form input{
		width: 100%;
		height: 50px;
		background: #FFFFFF;
		border: none;
		font-size: 15px;
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		
	}
	.main .form .row-code{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 25px;
	}
	.main .form .row-code .btn-upload{
		width: 117px;
		height: 50px;
		background: #7ECDF6;
		border: 1px solid #EAE8EB;
		border-radius: 7px;
		font-size: 16px;
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.main .form .row-code .btn-upload .msg{
		font-size: 9px;
	}
	.main .form .forget{
		color: #1F2A66;
	}
	.main .form .btn-login{
		width: 100%;
		height: 50px;
		background: #1F2A66;
		border-radius: 8px;
		font-size: 21px;
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		color: #FFFFFF;
		margin-bottom: 25px;
	}
	.main .form .link-reg{
		font-size: 15px;
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		color: #1F2A66;
		text-align: center;
	}
	.foot{
		font-size: 16px;
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		color: #364284;
		/* position: absolute; */
		/* bottom: 10px; */
		/* left: 50%; */
		text-align: center;
		/* transform: translate(-50%,0); */
	}
	.form-required{
		font-size: 18px;
		color: red;
		position: absolute;
		left: -15px;
		transform: translate(0,60%);
	}
</style>
